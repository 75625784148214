<template>
  <div class="add-payment">
    <error :err.sync="error" />
    <advanced-data-table
      :search="false"
      :headers="headers"
      :items="royaltiesRaw"
      :total-items="royaltiesRaw.length"
      :paginate="false"
      :loadingGlobal="loading"
      sticky-header="60vh"
      @row-clicked="rowClicked"
      ref="table"
      hide-actions
    >
      <template v-slot:cell(selected)="{ item, field: { key } }">
        <b-checkbox v-model="item[key]"></b-checkbox>
      </template>

      <template #cell(amount)="{item}">
        {{
          formatCurrency(
            item.base_currency_final_amount,
            item.base_currency.code
          )
        }}
      </template>

      <template #cell(commission)="{item}"> {{ item.commission }}% </template>

      <template #cell(sales_channel)="{item}">
        {{ item.sales_channel.name }}
      </template>

      <template #cell(book)="{item}">
        <router-link
          target="_blank"
          :to="{
            name: 'AdminEditBook',
            params: { id: item.book.id }
          }"
        >
          {{ item.book.title }}
        </router-link>
      </template>

      <template #cell(date_of_sale)="{item}">
        {{ formatDate(item.sale_record.date_of_sale) }}
      </template>

      <template #cell(status)="{item}">
        <!-- Use statusVariant from @/mixins/colors.js -->
        <h5 class="m-0">
          <b-badge :variant="statusVariant(item.status)" class="mr-1">{{
            $t(item.status)
          }}</b-badge>
        </h5>
      </template>

      <template #filters>
        <!-- Client filter -->
        <adt-filter :label="$t('client')">
          <item-search
            action="users/search"
            label="name"
            trackBy="id"
            v-model="client_id"
          />
        </adt-filter>

        <!-- Date range filter -->
        <adt-filter :label="$t('date-range')">
          <date-picker
            v-model="date_range"
            :placeholder="$t('date-range')"
            range
            valueType="format"
          />
        </adt-filter>

        <!-- Go Button -->
        <adt-filter>
          <ebp-button @click="get" :loading="loading" className="go-button"
            >{{ $t("go") }}
            <i class="bx bx-right-arrow-alt"></i>
          </ebp-button>
        </adt-filter>

        <adt-filter
          v-if="paymentDetails && client_id && royaltiesRaw.length"
          align-end
        >
          <ebp-button
            v-if="paymentDetails.payment_method"
            variant="dark"
            @click="showPaymentDetails = true"
            :loading="loading"
            style="width: 220px !important"
            >{{ $t("view-payment-method") }}
          </ebp-button>
          <b-badge variant="danger" v-else>{{
            $t("no-payment-details")
          }}</b-badge>
        </adt-filter>
      </template>

      <template #footer v-if="selected.length">
        <div class="floating-control">
          <div class="wrapper">
            <div class="top-panel d-flex justify-content-between">
              <!-- Subtotal -->
              <p>
                {{
                  $t("subtotal", {
                    total: formatCurrency(total, primaryCurrency)
                  })
                }}
              </p>

              <!-- Total commission value -->
              <p>
                {{
                  $t("total-commission", {
                    total: formatCurrency(totalCommission, primaryCurrency)
                  })
                }}
              </p>

              <!-- Total commission value -->
              <p>
                {{
                  $t("total-payment", {
                    total: formatCurrency(
                      total - totalCommission,
                      primaryCurrency
                    )
                  })
                }}
              </p>
            </div>

            <div class="input-cont">
              <div class="d-flex">
                <b-input
                  size="sm"
                  v-model="notes"
                  :placeholder="$t('notes')"
                  class="mr-2"
                />
                <b-input
                  size="sm"
                  type="number"
                  v-model="adjustment_amount"
                  :placeholder="$t('adjustment')"
                />
                <b-select
                  size="sm"
                  v-model="payment_method"
                  :placeholder="$t('payment-method')"
                  :options="
                    addAllOption(paymentMethodsDropdown, 'select-method')
                  "
                  class="ml-2"
                />
              </div>
              <ebp-button
                :loading="adding"
                size="sm"
                className="ml-1 go-button"
                @click="add"
                variant="secondary"
              >
                {{ $t("go") }}
              </ebp-button>
            </div>
          </div>
        </div>
      </template>
    </advanced-data-table>

    <ebp-modal
      :open.sync="showPaymentDetails"
      :tabs="['payment-details']"
      v-if="paymentDetails && client_id && royaltiesRaw.length"
    >
      <template #payment-details>
        <spinner v-if="loadingPaymentDetails" />
        <payment-details
          :users="[royaltiesRaw[0] ? royaltiesRaw[0].user : null]"
          :preferences="paymentDetails || {}"
          v-else
        />
      </template>
    </ebp-modal>
  </div>
</template>

<script>
import AdvancedDataTable from "@/components/advanced-data-table";
import PaymentDetails from "@/components/PaymentDetails";
import EbpModal from "@/components/ebp-modal";
import AdtFilter from "@/components/core/adt-filter";
import ItemSearch from "@/components/core/item-search";
import { sum } from "lodash-es";
import { mapState } from "vuex";
import DatePicker from "@/components/core/DatePicker";

export default {
  name: "add-royalties",
  components: {
    AdtFilter,
    AdvancedDataTable,
    ItemSearch,
    EbpModal,
    PaymentDetails,
    DatePicker
  },
  data() {
    return {
      headers: [
        "selected",
        "amount",
        "commission",
        "sales_channel",
        "book",
        "date_of_sale",
        "status"
      ],
      date_range: null,
      client_id: null,
      loading: false,
      adding: false,
      royaltiesRaw: [],
      royalties: [],
      error: null,
      adjustment_amount: null,
      payment_method: null,
      listener: null,
      showPaymentDetails: false,
      loadingPaymentDetails: false,
      paymentDetails: null,
      notes: null
    };
  },
  mounted() {
    this.$store.dispatch("currencies/get");
    this.$store.dispatch("ancillaries/getPaymentMethods");
    this.addScrollListener();
    this.client_id = this.$route.query.client_id;
    if (this.client_id) {
      this.getPaymentDetails(this.client_id);
      this.get();
    }
  },
  computed: {
    ...mapState("currencies", ["currencies"]),
    ...mapState("ancillaries", ["paymentMethods"]),
    total() {
      return (
        sum(this.selected.map(record => record.base_currency_final_amount)) +
        parseFloat(this.adjustment_amount || 0)
      );
    },
    totalCommission() {
      return sum(
        this.selected.map(
          record =>
            (record.base_currency_final_amount / 100) * record.commission
        )
      );
    },
    primaryCurrency() {
      const currency = find(this.currencies, c => c.is_primary);
      return currency ? currency.code : "GBP";
    },
    selected() {
      return this.royaltiesRaw.filter(i => i.selected);
    },
    paymentMethodsDropdown() {
      return this.paymentMethods.map(m => ({
        text: this.$t(m.name),
        value: m.id
      }));
    }
  },
  methods: {
    async getPaymentDetails(id) {
      this.loadingPaymentDetails = true;
      try {
        const res = await this.$store.dispatch("userPreferences/get", id);
        this.paymentDetails = res.data;
      } catch (err) {
        console.error(err);
      }
      this.loadingPaymentDetails = false;
    },
    rowClicked(item) {
      if (item.selected) {
        this.$set(item, "selected", false);
      } else {
        this.$set(item, "selected", true);
      }
    },
    addScrollListener() {},
    async add() {
      this.error = null;
      if (this.total < 0) {
        this.error = "validation-errors.amount-less-than-zero";
      } else if (!this.payment_method) {
        this.error = "validation-errors.payment-method-required";
      } else {
        this.adding = true;

        try {
          const res = await this.$store.dispatch("outboundPayments/create", {
            royalty_ids: this.selected.map(r => r.id),
            adjustment_amount: this.adjustment_amount,
            commission: this.totalCommission,
            final_amount: this.total - parseFloat(this.adjustment_amount || 0),
            user_id: this.client_id,
            payment_method: this.payment_method,
            notes: this.notes
          });
          this.$router.push({
            name: "ViewRoyalties",
            query: {
              outbound_payment_id: res.data.outbound_payment_id
            }
          });
        } catch (err) {
          console.error(err);
        }

        this.adding = false;
      }
    },
    async get() {
      this.error = null;
      const { client_id } = this;
      if (!client_id) {
        this.error = this.$t("validation-errors.required-all");
      } else {
        this.loading = true;

        try {
          const res = await this.$store.dispatch("royalties/queryByClient", {
            user_id: client_id,
            status: "received",
            date_range: (this.date_range || []).join(",").trim()
          });

          const selected = res.data.map(i => ({
            ...i,
            selected: true
          }));

          this.royaltiesRaw = selected;
          this.royalties = selected.slice(0, 35);
        } catch (err) {
          console.error(err);
        }

        this.loading = false;
      }
    }
  },
  watch: {
    client_id(id) {
      if (id) {
        this.getPaymentDetails(this.client_id);
      } else {
        this.royaltiesRaw = [];
        this.royalties = [];
      }
    }
  }
};
</script>

<style lang="scss">
.add-payment {
  .checkbox {
    background: white;
    border: 2px solid $primary;
    border-radius: 5px;
    color: white;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    &.checked {
      background: $primary;
    }
  }

  .floating-control {
    width: 100%;
    display: flex;
    justify-content: center;

    p {
      margin: 0;
    }

    .wrapper {
      width: 85%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      background: $primary;
      color: white;
      padding: 1rem;
      box-sizing: border-box;
      border-radius: 10px;
      * > {
        flex: 1;
      }

      .top-panel {
        margin-bottom: 1em;
        p {
          margin-right: 10em;
        }
      }

      .input-cont {
        display: flex;
      }
    }
  }

  .sweet-content {
    padding: 10px !important;
  }

  .list-group-item {
    border: 0;
  }
}
</style>
